{{item.BookingEmployees | json}}
<div style="
    text-align: start;
    margin: 10px 5px;
    background-color: #fff;
    page-break-after: always;" *ngFor="let employeeId of item.BookingEmployees; let e = index" [dir]="dirc"
  [ngStyle]="{'page-break-after': e != item.BookingEmployees.length - 1 ? 'always' : ''}">
  <div style="">

    <div class="row">
      <div class="col-lg-12 " style="text-align: center;">
        <div style="text-align: center;width: 100%;" *ngIf="AccountSetup?.ImagePath">
          <img [src]="AccountSetup?.ImagePath" style="width: 100px;text-align: center;" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 " style="text-align: center;">
        <p class="hed" style="
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        color: #000;
        font-weight: 600;
        font-size: 16px; ">
          {{ sharedService.Lang == 'ar' ? sharedService.BranchNameAr : sharedService.BranchNameEn }} &nbsp; {{
          printLang }}
        </p>
      </div>
    </div>

    <hr />

    <table style="width: 100%" [dir]="dirc">
      <tr class="row">
        <td class="col-3 text-start">
          <p style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ "BookingDate" | translate }} : {{ item.Date | date: "dd/MM/yyyy" }}
          </p>
          <p style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ "BookingTime" | translate }} : {{ item.Date | date: "hh:mm a" }}
          </p>

        </td>



        <td class="col-6 text-center">
          <!-- <img src="assets/img/g-logo.png" style="width: 60px;"> -->
          <div style="text-align: center" *ngIf="AccountSetup?.ImagePath">
            <img [src]="AccountSetup?.ImagePath" style="width: 60px" />
          </div>
          <p class="hed" style="
              margin: 0;
              padding: 0;
              text-align: center;
              color: #000;
              font-weight: 600;
              font-size: 16px; ">
            {{ sharedService.BranchNameAr
            }} &nbsp; {{ printLang }}
          </p>

          <p class="hed" style="
              margin: 0;
              padding: 0;
              text-align: center;
              color: #000;
              font-weight: 600;
              font-size: 16px;
            ">
            {{'BookingNumber' | translate }}&nbsp;-&nbsp;{{item.Id}}
          </p>

          <!-- <p style="font-size: 14px; font-weight: 600; margin: 5px 0px" *ngIf="
                      sharedService.CurrentBranch
                        .AllowUseNumberOfPrintsInBills &&
                      sharedService.CurrentBranch.AllowShowNumberOfPrintsInBills
                    ">
            <span>{{ "PrintNumber:" | translate }}</span>
            {{ (item.EmployeesNumberOfPrints ?? 0) + 1 }} .
          </p> -->

        </td>
        <td class="col-3 text-end">
          <p style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ item.Id }}
          </p>
        </td>
      </tr>
    </table>
    <div>

      <div class="row">
        <div class="col-lg-12 " style="text-align: center;">
          <h2 style=" color:#000; font-weight:900;">
            {{ ('PrintEmployees'|translate) }}
          </h2>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-lg-12 " style="text-align: center;">
          <h4 style=" color:#000; font-weight:900;">
            {{ ('EmployeeName'|translate) + " - " + getEmployeeName(employeeId) }}
          </h4>
        </div>
      </div>

      <div *ngIf="CheckIfPackage(item.BookingServices)">
        <hr />
        <div *ngFor="let bookingService of item.BookingServices; let i = index">

          <div *ngFor="
              let packageService of bookingService.TransactionsPackageServices;
              let i = index
            ">
            <!-- [hidden]=" i != 0" -->
            <p *ngIf="packageService.EmployeeId == employeeId" style="
                font-family: initial;
                text-align: center;
                font-size: 24px;
                margin: 0;
                font-weight: 600;
                color: #000;
              ">
              {{
              printLang == "ar"
              ? packageService.Employee?.NameAr
              : packageService.Employee?.NameEn
              }}
            </p>
          </div>
        </div>

        <hr />
      </div>



      <!-- [dir]="printLang  == 'ar' ? 'rtl' : 'ltr'" -->
      <table style="width: 100%; text-align: start" [dir]="dirc">
        <tr>
          <td style="
              font-family: initial;
              font-size: 20px;
              font-weight: 500;
              width: 160px;
              color: #000;
            ">
            <p style="font-size: 16px; font-weight: 600; margin: 0px">
              {{ "ClientName" | translate }} :
              {{
              printLang == "ar"
              ? item.Client?.NameAr
              : item.Client?.NameEn
              }}
            </p>

            <p style="font-size: 16px; font-weight: 600; margin: 0px">
              {{ "UserName" | translate }} :
              {{ item.AddUser?.UserName }}
            </p>
          </td>
          <td style="text-align: end">
            <div style="text-align: end" *ngIf="AccountSetup?.QRImagePath">
              <img [src]="AccountSetup?.QRImagePath" alt="" style="width: 80px" />
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <div *ngIf="item.BookingServices.length > 0">
        <table border="1" style="
            width: 100%;
            border-collapse: collapse;
            text-align: start !important;
            margin: 15px 0;
            border: 2px solid #000;
          " [dir]="dirc">
          <thead style="text-align: start !important" [dir]="dirc">
            <tr style="text-align: start !important" [dir]="dirc">
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Name" | translate }}
              </th>
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Employee" | translate }}
              </th>
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Quantity" | translate }}
              </th>
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Price" | translate }}
              </th>
              <!-- <th style="text-align: start !important; color: #000; padding:5px;">{{ 'Commission' | translate }}
              </th> -->
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Total" | translate }}
              </th>
              <!--  <th *ngIf="sharedService?.CurrentBranch?.AllowTracingCommissionInCashSale == true &&
               this.hasNonNullEmployeeTransactionCommission(item)"
                style="text-align: start !important; color: #000; padding: 5px">
                {{ "Commission" | translate }}
              </th> -->

              <!-- <th *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
              sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
              sharedService?.CurrentBranch?.ClientsQueueSystemType == 2"
                style="text-align: start !important; color: #000; padding: 5px">
                {{ "QueueNumber" | translate }}
              </th> -->

            </tr>
          </thead>
          <tbody *ngFor="  let bookingService of item.BookingServices; let i = index ">
            <!-- && bookingService.TransactionsPackageServices.length == 0 -->
            <tr style="text-align: start" *ngIf="bookingService.EmployeeId === employeeId">
              <td style="color: #000; padding: 5px">
                {{
                bookingService.Service
                ? printLang == "ar"
                ? bookingService.Service.NameAr
                : bookingService.Service.NameEn
                : bookingService.product
                ? printLang == "ar"
                ? bookingService.product.NameAr
                : bookingService.product.NameEn
                : bookingService.Package
                ? printLang == "ar"
                ? bookingService.Package.NameAr
                : bookingService.Package.NameEn
                : bookingService.OffersSetting
                ? printLang == "ar"
                ? bookingService.OffersSetting.NameAr
                : bookingService.OffersSetting.NameEn
                : ""
                }}
                <div *ngIf="AccountSetup?.AllowUseRooms == true">
                  <span *ngFor="
                      let room of bookingService.ResourceManagements
                    ">
                    {{ room.ResourceName }} <br />
                    {{ "From" | translate }}:
                    {{ room.StartTime | date: "shortTime" }} ,
                    {{ "To" | translate }}:
                    {{ room.EndTime | date: "shortTime" }}
                  </span>
                </div>
              </td>
              <td style="color: #000; padding: 5px">


                {{ bookingService.Employee ? (printLang == "ar" ?
                bookingService.Employee?.NameAr :
                bookingService.Employee?.NameEn ): ('NotFound'|translate)}}


                {{ bookingService?.SecondEmployee ? " - " +
                (printLang == "ar" ?
                bookingService.SecondEmployee?.NameAr :
                bookingService.SecondEmployee?.NameEn) : "" }}
              </td>
              <td style="color: #000; padding: 5px">
                {{ bookingService.Quantity }}
              </td>
              <td *ngIf="sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees"
                style="color: #000; padding: 5px">
                {{ bookingService.Price.toFixed(sharedService.GetFractionRoundCount) }}
              </td>
              <!-- <td style="color: #000; padding:5px;">
                {{bookingService?.EmployeeTransactionCommission[0]?.CommissionValue }} </td> -->
              <td *ngIf="sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees"
                style="color: #000; padding: 5px">
                {{ bookingService.TotalNetPrice.toFixed(sharedService.GetFractionRoundCount) }}
              </td>

              <!--  <td *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
              sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
              sharedService?.CurrentBranch?.ClientsQueueSystemType == 2" style="color: #000; padding: 5px">
                {{ bookingService.ClientNumberInQueue }}
              </td> -->
            </tr>



            <tr *ngIf="
                bookingService.TransactionsPackageServices &&
                bookingService.TransactionsPackageServices.length != 0 &&
                bookingService.ItemType == 2
              ">
              <td colspan="5">
                <table style="width: 100%">
                  <tbody *ngFor="
                      let packageService of bookingService.TransactionsPackageServices;
                      let i = index
                    ">
                    <tr *ngIf="packageService.EmployeeId == employeeId">
                      <td>{{ "Package" | translate }}</td>
                      <td colspan="5">
                        <table border="1" style="width: 100%">
                          <tbody>
                            <tr>
                              <td>
                                <p style="font-size: 13px">
                                  {{
                                  printLang ==
                                  "ar"
                                  ? packageService.Service?.NameAr
                                  : packageService.Service?.NameEn
                                  }}
                                </p>
                              </td>
                              <td style="color: #000; padding: 5px">
                                {{
                                printLang =="ar"? packageService.Employee?.NameAr
                                : packageService.Employee?.NameEn}}
                              </td>
                              <td>
                                {{ packageService.ServicePriceInPackage.toFixed(sharedService.GetFractionRoundCount)}}
                              </td>
                              <td>
                                {{
                                packageService?.EmployeeTransactionCommission
                                ?.CommissionValue
                                }}
                              </td>
                              <td>
                                {{
                                packageService.ServicePriceInPackageWithTax.toFixed(
                                2
                                )
                                }}
                              </td>
                            </tr>

                            <tr *ngIf="packageService?.EmployeeTransactionCommission &&
                            AccountSetup?.AllowTracingCommissionInCashSale ==true
                              ">

                              <td colspan="6">
                                <div style="
                                    display: flex;
                                    justify-content: space-between;
                                  ">
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    ">
                                    {{ "Commission" | translate }}
                                    {{
                                    packageService
                                    ?.EmployeeTransactionCommission
                                    ?.CommissionValue
                                    }}
                                  </p>
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    ">
                                    المستهدف :
                                    {{
                                    packageService
                                    ?.EmployeeTransactionCommission
                                    ?.TargetCommissionValue
                                    }}
                                  </p>
                                </div>
                                <div style="
                                    display: flex;
                                    justify-content: space-between;
                                  ">
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    ">
                                    اجمالى المحقق :
                                    {{
                                    packageService
                                    ?.EmployeeTransactionCommission
                                    ?.TotalAchievedServicePrice
                                    }}
                                  </p>

                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    " *ngIf="
                                      packageService
                                        ?.EmployeeTransactionCommission
                                        ?.IsTargetAccomplished == true
                                    ">
                                    تم تحقيق التارجت
                                  </p>
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    " *ngIf="
                                      packageService
                                        ?.EmployeeTransactionCommission
                                        ?.IsTargetAccomplished == false
                                    ">
                                    لم يتم تحقيق التارجت
                                  </p>
                                </div>
                                <div style="
                                    display: flex;
                                    justify-content: space-between;
                                  ">
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    ">
                                    الايام المتبقية لتحقيق التارجت :
                                    {{
                                    packageService
                                    ?.EmployeeTransactionCommission
                                    ?.RemainingTargetDays
                                    }}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style="
          text-align: start;
          font-size: 14px;
          font-weight: 500;
          color: #000;
        ">
        {{ item.Comment }}
      </p>
      <!-- <hr />
      <div></div>
      <div style="width: 100%; height: 1px; border: 1px solid #000; margin: 5px"></div>
      <p style="text-align: center; font-weight: 500; color: #000">
        {{ AccountSetup?.PrintReceiptAdvertText }}
      </p> -->


      <div style="display: flex; align-items: center; justify-content: center;">
        <span *ngIf="AccountSetup?.AllowUseClientsQueueSystem &&
          AccountSetup?.AllowShowClientsQueueNumberInPrintEmployees &&
          AccountSetup?.ClientsQueueSystemType == 1 &&
          item.ClientNumberInQueue && item.ClientNumberInQueue > 0" style="margin-right:30px;">

          <div style="text-align: center;">
            <span style="display: block; font-size: 25px; font-weight: 700;">{{ "ClientNumberInQueue" | translate
              }}</span>
          </div>
          <p style="text-align: center; font-weight: 700; color: #000;
font-family: initial;font-size: 60px; font-weight: 700;margin: 15; background-color: #373333  !important;
color: white;padding: 5px; border-radius: 5px; -webkit-print-color-adjust: exact !important;">
            <span>{{ item?.ClientNumberInQueue }}</span>
          </p>
        </span>
      </div>

    </div>
  </div>
</div>
