import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/other/shared.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import * as _ from 'underscore';
import { BookingOrTransactionItemType } from 'src/app/models/enums/booking-or-transaction-item-type';

@Component({
  selector: 'app-print-items-booking',
  templateUrl: './print-items-booking.component.html',
  styleUrls: ['./print-items-booking.component.scss']
})
export class PrintItemsBookingComponent implements OnInit {

  public item;

  @Input('Booking')
  set in(item) {

    this.item = item;

    if (item?.BookingServices && item?.BookingServices?.length > 0) {
      this.handleBookingItems();
    }

  }

  @Input() public readonly AccountSetup;
  @Input() public dirc;
  public BillInfoUrl: string;
  public BillInfoForPackageUrl: string;
  public printLang: string = 'ar';

  constructor(
    public sharedService: SharedService,
    public translate: TranslateService,

  ) {

  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;

  ngOnInit(): void {



    this.BillInfoUrl = location.origin + "/transaction/bill-info/billItemId/";
    this.BillInfoForPackageUrl = location.origin + "/transaction/bill-info/billPackageItemId/";

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'en') {
      // this.translate.use('en');
      // console.log(this.AccountSetup?.SelectedPrintEmployeeLanguage)
      // this.translate.use('en');
      this.dirc = 'ltr';
      this.sharedService.TempLangKey = "en";
      this.sharedService.SetTranslationPipeData();
    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'ar') {
      // this.translate.use('ar');
      this.dirc = 'rtl';
      this.sharedService.TempLangKey = "ar";
      this.sharedService.SetTranslationPipeData();

    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'all') {
      console.log('all')

    }

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage && this.AccountSetup?.SelectedPrintEmployeeLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintEmployeeLanguage;
    }

  }

  hasNonNullEmployeeBookingCommission(item: any): boolean {
    return item.BookingServices.some(service => service.EmployeeBookingCommission
      && service.EmployeeBookingCommission.CommissionValue > 0);
  }

  handleBookingItems() {


    this.item.BookingItems = [];

    this.item.BookingServices.forEach((bookingItem, index) => {

      if (bookingItem.ServiceId > 0) {
        bookingItem.ItemId = bookingItem.ServiceId;

        bookingItem.ItemType = BookingOrTransactionItemType.Service;
      } else if (bookingItem.PackageId > 0) {
        bookingItem.ItemId = bookingItem.PackageId;
      } else if (bookingItem.GiftCardId > 0) {
        bookingItem.ItemId = bookingItem.GiftCardId;
      } else if (bookingItem.ProductId > 0) {

        bookingItem.ItemType = BookingOrTransactionItemType.Product;
        bookingItem.ItemId = bookingItem.ProductId;
      }

      bookingItem.ItemGlobalName = bookingItem.Service ?
        (this.sharedService.Lang == 'ar' ?
          bookingItem.Service.NameAr : bookingItem.Service.NameEn) : null;

      bookingItem.Total = bookingItem.TotalNetPrice;

      this.item.BookingItems.push(bookingItem);

      if (bookingItem.BookingsPackageServices &&
        bookingItem.BookingsPackageServices.length > 0) {

        bookingItem.BookingsPackageServices.forEach((transactionPackageServices, index) => {

          if (transactionPackageServices.ServiceId > 0) {

            transactionPackageServices.ItemType = BookingOrTransactionItemType.Service;
            transactionPackageServices.ItemId = transactionPackageServices.ServiceId;
            transactionPackageServices.Quantity = 1;
            transactionPackageServices.OriginalPrice = transactionPackageServices.ServicePriceInPackage ?? 0;
            transactionPackageServices.Total = transactionPackageServices.ServicePriceInPackage;
            transactionPackageServices.Note = "PackageService";
            transactionPackageServices.ItemGlobalName = this.sharedService.Lang == 'ar' ?
              transactionPackageServices.Service.NameAr : transactionPackageServices.Service.NameEn;
          }

          this.item.BookingItems.push(transactionPackageServices);

        });
      }
    });

  }

  getItemName(transItem: any): string {

    var getBookingItem = this.item.BookingItems.
      find(ti => ti.ItemType == transItem.ItemType && ti.ItemId == transItem.ItemId);

    if (getBookingItem) {

      var itemName = getBookingItem.ItemGlobalName ? getBookingItem.ItemGlobalName :
        this.translate.instant('NotFound');

      return itemName;
    }

    return this.translate.instant('NotFound');

  }
  getCategoryNameName(transItem: any): string {

    var getBookingItem = this.item.BookingItems.
      find(ti => ti.ItemType == transItem.ItemType && ti.ItemId == transItem.ItemId);

    if (getBookingItem && (getBookingItem?.service?.Category ||
      getBookingItem?.product?.Category)) {

      var categoryName = getBookingItem?.service?.Category?.GlobalName ??
        getBookingItem?.product?.Category?.GlobalName;

      return categoryName;
    }

    return null;

  }

}
